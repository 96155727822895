.query-table-forms {
    grid-row: 2;
    height: 400px;
    overflow: auto;
    width: 100%;
    margin-bottom: 0;
    background: #f5f5f5;
    border: 1px solid #eee;
    position: relative;
}

.scrollTableAQuestion{
  max-height: 200px;
  overflow: auto;
}
 
.inputView {
  font-weight: bold
}

.title{
  font-size: medium;
  color:white
}